/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux';
import { selectors as UserSelectors } from '../../redux/UserRedux';

import {
  getAllAppointmentsByUserId,
  updateAppointmentById,
  updateStatusGuestAppointment,
  getAppointmentById,
} from '../../api/AppointmentsApi';

import AddAppointmentPopin from '../../containers/popins/AddAppointmentPopin/AddAppointmentPopin';
import { AgendaCalendar } from '../../components/molecules/AgendaCalendar/AgendaCalendar';
import { AgendaDate } from '../../components/molecules/AgendaDate/AgendaDate';
import { AgendaRow } from '../../components/molecules/AgendaRow/AgendaRow';
import AgendaSidebar from '../../components/molecules/AgendaSidebar/AgendaSidebar';
import BellIcon from './bell.svg';
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside';
import CircleButton from '../../components/atoms/CircleButton/CircleButton';
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import { DeclineAppointmentPopin } from '../../containers/popins/DeclineAppointmentPopin/DeclineAppointmentPopin';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import { SorterButton } from '../../components/atoms/SorterButton/SorterButton';
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout';
import { Toaster } from '../../components/atoms/Toaster/Toaster';

import moment from 'moment';
import 'moment/locale/fr';

import { useToaster } from '../../hooks/useToaster';

import './AgendaPage.scss';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
});

const AgendaPage = ({ user, beneficiary }) => {
  const [appointments, setAppointments] = useState([]);
  const [appointmentPending, setAppointmentPending] = useState([]);
  const [appointmentRefused, setAppointmentRefused] = useState({});
  const [appointmentSelected, setAppointmentSelected] = useState(null);
  const [currentDate, setCurrentDate] = useState();
  const isBeneficiary = useSelector(UserSelectors.isBeneficiary);
  const isB = useSelector(UserSelectors.isBeneficiary);
  const [isBeneficiaryCalendar, setIsBeneficiaryCalendar] = useState(false);
  const [filterAll, setFilterAll] = useState(true);
  const [filterCollective, setFilterCollective] = useState(false);
  const [filterIndividual, setFilterIndividual] = useState(false);
  const [groupedAppointments, setGroupedAppointments] = useState();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [showAddAppointmentModal, setShowAddAppointmentModal] = useState(false);
  const [showDeclineAppointmentPopin, setShowDeclineAppointmentPopin] = useState(false);
  const [specificDate, setSpecificDate] = useState();
  const [weeks, setWeeks] = useState();
  const { toasters, addToaster, removeToaster } = useToaster();
  const [isHistory, setIsHistory] = useState(false);

  const scrollNearestAppointmentRef = useRef([]);

  const [userTimezone, setUserTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(timezone);
  }, []);

  const convertDatesInListToUserTimezone = (list, format) => {
    if (!userTimezone) {
        return list;
    }
        return list.map(item => {
        const convertedItem = {
            ...item,
            rdv_at: moment.tz(item.rdv_at, 'Europe/Paris').tz(userTimezone).format(format),
            rdv_to: moment.tz(item.rdv_to, 'Europe/Paris').tz(userTimezone).format(format)
        };
        return convertedItem;
    });
};


  const getAppointmentsSelect = (type = 'all') => {
    Promise.all([getAllIndivAppointments(), getAllGroupsAppointments()])
      .then(([individualAppointments, groupAppointments]) => {
        if (type === 'all') {
          setAppointmentPending(individualAppointments.pendingAppointments.concat(groupAppointments.pendingAppointments));
          setAppointments(individualAppointments.confirmedAppointments.concat(groupAppointments.confirmedAppointments));
        } else if (type === 'individual') {
          setAppointments(individualAppointments.confirmedAppointments);
          setAppointmentPending(individualAppointments.pendingAppointments);
        } else if (type === 'collective') {
          setAppointments(groupAppointments.confirmedAppointments);
          setAppointmentPending(groupAppointments.pendingAppointments);
        }
      })
      .catch((error) => {
        console.error('Une erreur s\'est produite lors de la récupération des rendez-vous:', error);
      });
  }
  
  // const getAllAppointments = () => {
  //   getAllAppointmentsByUserId(user.id)
  //     .then((response) => {
  //       const appointments = response.appointments;
  //       if (appointments.length === 0) {
  //         setAppointments([]);
  //         setAppointmentPending([]);
  //       } else {
  //         if (!isBeneficiaryCalendar) {
  //           const confirmedAppointments = appointments.filter((appointment) => {
  //             const userStatus = findUserInAppointment(user.id, appointment)?.status;
  //             return (
  //               (appointment.status !== 'pending' &&
  //               appointment.user_from !== user.id &&
  //               userStatus === 'ok')
  //               || !moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment())
  //             );
  //           });
  
  //           const ownersAppointments = appointments.filter((apt) => apt.user_from === user.id);
  //           const appointmentsPending = appointments.filter(
  //             (appointment) =>
  //               appointment.status === 'pending' &&
  //               moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment()),
  //           );
  //           setAppointmentPending(appointmentsPending);
  //           setAppointments(confirmedAppointments.concat(ownersAppointments));
  //         } else {
  //           const confirmedAppointments = appointments.filter((appointment) => {
  //             const userStatus = findUserInAppointment(user.id, appointment)?.status;
  //             return (
  //               (appointment.status !== 'pending' &&
  //               userStatus === 'ok') || !moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment())
  //             );
  //           });
  //           const pendingAppointments = appointments.filter((appointment) => {
  //             const userStatus = findUserInAppointment(user.id, appointment)?.status;
  //             return (
  //               userStatus === 'pending' &&
  //               moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment())
  //             );
  //           });
  //           setAppointments(confirmedAppointments);
  //           setAppointmentPending(pendingAppointments);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Une erreur s\'est produite lors de la récupération des rendez-vous:', error);
  //     });
  // }
  
  const getAllIndivAppointments = () => {
    return new Promise((resolve, reject) => {
      getAllAppointmentsByUserId(user.id)
        .then((response) => {
          const appointments = response.appointments;
          if (appointments.length > 0) {
            if (!isBeneficiary) {
              const individualAppointments = appointments.filter(
                (appointment) => appointment.appointment_type === 'Individual',
              );
  
              const confirmedIndividualAppointments = individualAppointments.filter((appointment) => {
                const userStatus = findUserInAppointment(user.id, appointment)?.status;
                return appointment.user_from !== user.id &&(userStatus === 'ok' || !moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment()));
              });
  
              const ownersIndividualAppointments = individualAppointments.filter(
                (appointment) => appointment.user_from === user.id,
              );

              const pendingIndividualAppointments = individualAppointments.filter((appointment) => {
                const userStatus = findUserInAppointment(user.id, appointment)?.status;
                return userStatus === 'pending' && moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment());
              });

              resolve({
                confirmedAppointments: convertDatesInListToUserTimezone(confirmedIndividualAppointments.concat(ownersIndividualAppointments), 'YYYY-MM-DD HH:mm:ss'),
                pendingAppointments: [],

              });
            } else {
              const individualAppointments = appointments.filter(
                (appointment) => appointment.appointment_type === 'Individual',
              );
              const confirmedIndividualAppointments = individualAppointments.filter((appointment) => {
                const userStatus = findUserInAppointment(user.id, appointment)?.status;
                return userStatus === 'ok' || !moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment());
              });

              const pendingIndividualAppointments = individualAppointments.filter((appointment) => {
                const userStatus = findUserInAppointment(user.id, appointment)?.status;
                return userStatus === 'pending' && moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment());
              });
              resolve({
                confirmedAppointments: convertDatesInListToUserTimezone(confirmedIndividualAppointments, 'YYYY-MM-DD HH:mm:ss'),
                pendingAppointments: convertDatesInListToUserTimezone(pendingIndividualAppointments, 'YYYY-MM-DD HH:mm:ss'),
              });
            }
          } else {
            resolve({
              confirmedAppointments: [],
              pendingAppointments: []
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
  const getAllGroupsAppointments = () => {
    return new Promise((resolve, reject) => {
      getAllAppointmentsByUserId(user.id)
        .then((response) => {
          const appointments = response.appointments;
          if (appointments.length > 0) {
            if (!isBeneficiary) {
              const collectiveAppointments = appointments.filter(
                (appointment) => appointment.appointment_type === 'Collective',
              );
              const confirmedCollectiveAppointments = collectiveAppointments.filter((appointment) => {
                const userStatus = findUserInAppointment(user.id, appointment)?.status;
                return appointment.user_from !== user.id &&(userStatus === 'ok' || !moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment()));
              });
              const ownersCollectiveAppointments = collectiveAppointments.filter(
                (appointment) => appointment.user_from === user.id,
              );
              resolve({
                confirmedAppointments: convertDatesInListToUserTimezone(confirmedCollectiveAppointments.concat(ownersCollectiveAppointments), 'YYYY-MM-DD HH:mm:ss'),
                pendingAppointments: []
              });
            } else {
              const collectiveAppointments = appointments.filter(
                (appointment) => appointment.appointment_type === 'Collective',
              );
              const confirmedCollectiveAppointments = collectiveAppointments.filter((appointment) => {
                const userStatus = findUserInAppointment(user.id, appointment)?.status;
                return userStatus === 'ok' || !moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment());
              });
              const pendingCollectiveAppointments = collectiveAppointments.filter((appointment) => {
                const userStatus = findUserInAppointment(user.id, appointment)?.status;
                return userStatus === 'pending' && moment(appointment.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(moment());
              });
              resolve({
                confirmedAppointments: convertDatesInListToUserTimezone(confirmedCollectiveAppointments, 'YYYY-MM-DD HH:mm:ss'),
                pendingAppointments: convertDatesInListToUserTimezone(pendingCollectiveAppointments, 'YYYY-MM-DD HH:mm:ss'),
              });
            }
          } else {
            resolve({
              confirmedAppointments: [],
              pendingAppointments: []
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // methods

  const findUserInAppointment = (userId, appointment) => {
    const foundUserInIndividuals = appointment.users_to.individuals.find(
      (participant) => participant.user_id === userId,
    );
    if (foundUserInIndividuals) {
      return foundUserInIndividuals;
    }

    for (const group of appointment.users_to.groups) {
      const foundUserInGroup = group.group_users.find(
        (participant) => participant.user_id === userId,
      );
      if (foundUserInGroup) {
        return foundUserInGroup;
      }
    }
    return null;
  };

  const getValueSorter = (filter) => {
    if (filter.value === 'all') {
      setFilterAll(true);
      setFilterIndividual(false);
      setFilterCollective(false);
      // setSelectedAppointmentId(null);
      // setAppointmentSelected(null);
      getAppointmentsSelect('all');
    } else if (filter.value === 'individual') {
      getAppointmentsSelect('individual');
      setFilterAll(false);
      setFilterIndividual(true);
      setFilterCollective(false);
      // setSelectedAppointmentId(null);
      // setAppointmentSelected(null);
    } else if (filter.value === 'collective') {
      getAppointmentsSelect('collective');
      setFilterAll(false);
      setFilterIndividual(false);
      setFilterCollective(true);
      // setSelectedAppointmentId(null);
      // setAppointmentSelected(null);
    }
  };

  const getAppointmentSelected = (appointment) => {
    getAppointmentById(appointment.id).then((response) => {
      setAppointmentSelected(response.appointment);
    });
    
  };

  const onClickBackSidebar = (isClicked) => {
    if (isClicked) {
      setAppointmentSelected(null);
      setAppointments((prevAppointments) => {
        return prevAppointments.map((apt) => {
          return {
            ...apt,
            isSelected: false,
          };
        });
      });
      const e = document.getElementsByClassName('calendar-page-right-side')[0];
      if (e.classList.contains('agenda-fixed-view')) {
        e.classList.remove('agenda-fixed-view');
      }
    }
  };

  const handleAddAppointment = () => {
    setShowAddAppointmentModal(true);
  };

  const getWeekFromDate = (date) => {
    const momentDate = moment(date);
    return momentDate.startOf('week');
  };

  const sortByDate = (appointmentA, appointmentB) => {
    const dateA = new Date(appointmentA.rdv_at);
    const dateB = new Date(appointmentB.rdv_at);
    return dateA - dateB;
  };

  const groupAppointmentsByWeek = () => {
    const groupedAppointments = {};
    const sortedAppointments = appointments
      .filter((apt) =>
        moment(apt.rdv_at, 'YYYY-MM-DD HH:mm:ss').isAfter(
          isHistory ? moment('1970-04-15 12:00') : moment(),
        ),
      )
      .sort(sortByDate);

    sortedAppointments.forEach((apt) => {
      const week = getWeekFromDate(apt?.rdv_at);
      if (!groupedAppointments[week]) {
        groupedAppointments[week] = [];
      }
      groupedAppointments[week].push(apt);
    });
    setGroupedAppointments(groupedAppointments);
    return groupedAppointments;
  };

  const getWeeks = (group) => {
    const weeks = Object.keys(group);
    setWeeks(weeks);
  };

  const formatedDate = (date) => {
    const startWeek = moment(date);
    const endWeek = moment(date).endOf('week');

    if (startWeek.month() === endWeek.month()) {
      return `${startWeek.format('D')} - ${endWeek.format('LL')}`;
    } else {
      return `${startWeek.format('DD MMMM')} - ${endWeek.format('LL')}`;
    }
  };

  const getDateViewSpecificAppointment = (date) => {
    const parsedDate = moment(date, 'DD/MM/YYYY');
    setSpecificDate(parsedDate);
  };

  const scrollNearAppointment = () => {
    const isAppointmentPending = appointmentPending.length > 0;

    if (!isAppointmentPending) {
      const currentDateStartWeek = moment(currentDate).startOf('week');
      let nearestAppointmentRef = null;
      let nearestAppointmentDistance = Infinity;

      weeks.forEach((week, index) => {
        const appointmentDate = moment(week);
        if (appointmentDate.isSameOrAfter(currentDateStartWeek)) {
          const distance = Math.abs(currentDateStartWeek.diff(appointmentDate));

          if (distance < nearestAppointmentDistance) {
            nearestAppointmentDistance = distance;
            nearestAppointmentRef = scrollNearestAppointmentRef.current[index];
          }
        }
        if (nearestAppointmentRef) {
          nearestAppointmentRef.scrollIntoView({ behavior: 'smooth' });
          const indexSection = scrollNearestAppointmentRef.current.indexOf(nearestAppointmentRef);
          const nearApt = moment(weeks[indexSection]).format('YYYY-MM-DD HH:mm:ss');
          const nearestOrEqualAppointment = appointments.find((apt) =>
            moment(apt.rdv_at).isSameOrAfter(nearApt),
          );

          setSelectedAppointmentId(nearestOrEqualAppointment.id);
        }
      });
    }
  };

  const scrollSpecificAppointment = () => {
    const specificDateStartWeek = moment(specificDate).startOf('week');

    if (specificDate) {
      const index = weeks.findIndex((week) => moment(week).isSame(specificDateStartWeek, 'day'));

      if (index !== -1 && scrollNearestAppointmentRef.current[index]) {
        scrollNearestAppointmentRef.current[index].scrollIntoView({ behavior: 'smooth' });
        const indexSection = scrollNearestAppointmentRef.current.indexOf(
          scrollNearestAppointmentRef.current[index],
        );
        const nearApt = moment(weeks[indexSection]).format('YYYY-MM-DD HH:mm:ss');
        const nearestOrEqualAppointment = appointments.find((apt) =>
          moment(apt.rdv_at).isSameOrAfter(nearApt),
        );
        setSelectedAppointmentId(nearestOrEqualAppointment.id);
      }
    }
  };

  const getNewAppointment = (newAppointment) => {
    setAppointments([...appointments, newAppointment]);
    setShowAddAppointmentModal(false);
    window.location.reload();
  };

  const getDeleteAppointment = (deleteAppointment) => {
    const appointmentsUpdated = appointments.filter((app) => app.id !== deleteAppointment.id);
    setAppointments(appointmentsUpdated);
    setAppointmentSelected(null);
  };

  const getEditAppointment = (editAppointment) => {
    //TODO : J'AI COMMENTE PARCE QUE LE CODE DOIT PLUS ETRE D'ACTUALITE
    // if(editAppointment.status === "ok") {
    setAppointments((prevAppointments) => {
      return prevAppointments.map((app) =>
        app && editAppointment && app.id === editAppointment.id ? editAppointment : app,
      );
    });
    // }
    // else {
    //   const filterAppointments = appointments.filter(apt => apt.id !== editAppointment.id);
    //   setAppointments(filterAppointments);
    // }
    setAppointmentSelected(null);
  };

  const handleAppointmentSelect = (id) => {
    if (selectedAppointmentId === id) {
      setSelectedAppointmentId(null);
    } else {
      setSelectedAppointmentId(id);
    }
  };

  const getToasters = (toaster) => {
    addToaster(toaster.title, toaster.message, toaster.type);
  };

  // methods beneficiary side
  const handleActionsConfirmationAppointment = (statusConfirmation) => {
    const { appointment, status } = statusConfirmation;
    const updateAppointment = { ...appointment, status: status };
    const updateStatusUser = { status: status, comment: '' };

    // update appointment status
    updateAppointmentById(appointment.id, updateAppointment).then((response) => {
      status === 'ok' && setAppointments([...appointments, response.appointment]);
      const filterPendingAppointments = appointmentPending.filter(
        (apt) => apt.id !== appointment.id,
      );
      setAppointmentPending(filterPendingAppointments);
      getAppointmentsSelect('all');
    });

    // update user status
    updateStatusGuestAppointment(appointment.id, user.id, updateStatusUser);
    setShowDeclineAppointmentPopin(false);
    setAppointmentSelected(null);
    if (status === 'ko') {
      addToaster(
        'Refus rendez - vous',
        "Vous avez bien refusé l'invitation du rendez - vous",
        'success',
      );
      const filterAppointments = appointments.filter(
        (apt) => apt.id !== appointmentRefused.appointment.id,
      );
      setAppointments(filterAppointments);
    }
  };

  const getDeclineAppointment = (declineAppointment) => {
    setShowDeclineAppointmentPopin(true);
    setAppointmentRefused({
      appointment: declineAppointment.appointment,
      status: declineAppointment.status,
    });
    getAppointmentsSelect('all');
  };

  const getReportAppointment = (reportAppointment) => {
    const filterAppointments = appointmentPending.filter((apt) => apt.id !== reportAppointment.id);
    setAppointmentPending(filterAppointments);
    getAppointmentsSelect('all');
  };

  // use effect
  useEffect(() => {
    if (beneficiary) {
      setIsBeneficiaryCalendar(true);
      // isBeneficiary = true;
    } else {
      setIsBeneficiaryCalendar(isB);
    }
    getAppointmentsSelect('all');
    setCurrentDate(moment());
  }, [isBeneficiaryCalendar]);

  useEffect(() => {
    appointments && getWeeks(groupAppointmentsByWeek());
  }, [appointments, isHistory]);

  useEffect(() => {
    weeks && scrollNearAppointment();
  }, [weeks]);

  useEffect(() => {
    scrollSpecificAppointment();
  }, [specificDate]);

  // render components
  const renderAppointmentsConfirmation = () => {
    const countAppointment = appointmentPending.length;
    return (
      <div className="calendar-page-pending-appointments">
        <div className="calendar-page-pending-appointments-header">
          <div className="bell-icon">
            <BellIcon />
          </div>
          {isBeneficiaryCalendar ? (
            <h2>
              {countAppointment <= 1
                ? 'Veuillez confirmer le rendez-vous suivant :'
                : `Veuillez confirmer les ${countAppointment} rendez-vous suivants :`}
            </h2>
          ) : (
            <h2>
              {countAppointment <= 1
                ? `Rendez-vous en attente de confirmation de ${
                    appointmentPending.filter((apt) =>
                      moment(apt.rdv_to, 'YYYY-MM-DD HH:mm:ss').isAfter(
                        isHistory ? moment('1970-04-15 12:00') : moment(),
                      ),
                    )[0]?.title ?? beneficiary.first_name
                  } :`
                : `${countAppointment} Rendez-vous en attente de confirmation :`}
            </h2>
          )}
        </div>
        <div className="calendar-page-all-pending-appointments">
          <div
            style={{
              width: '100%',
              padding: '0 25px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          ></div>
          {appointmentPending.map((appointment, index) => (
            <div className="pending-appointments" key={index}>
              <AgendaDate date={appointment.rdv_at} />
              <AgendaRow
                type={appointment.appointment_type}
                appointment={appointment}
                setAppointmentSelected={getAppointmentSelected}
                isBeneficiary={isBeneficiary}
                setActionsConfirmationAppointment={handleActionsConfirmationAppointment}
                user={user}
                setDeclineAppointment={getDeclineAppointment}
                setReportAppointment={getReportAppointment}
                setToaster={getToasters}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderButtonsActions = () => {
    return <CircleButton icon={'plus'} color={'white'} onClick={handleAddAppointment} />;
  };

  useEffect(() => {}, []);

  const renderFilters = () => {
    return (
      <div>
        <div className="calendar-page-filters">
          <SorterButton
            label={'Tous'}
            value={'all'}
            sendValue={getValueSorter}
            active={filterAll}
            setActive={setFilterAll}
          />
          <SorterButton
            label={'Rendez-vous individuel'}
            value={'individual'}
            sendValue={getValueSorter}
            active={filterIndividual}
            setActive={setFilterIndividual}
          />
          <SorterButton
            label={'Atelier collectif'}
            value={'collective'}
            sendValue={getValueSorter}
            active={filterCollective}
            setActive={setFilterCollective}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <SorterButton
            label={'Rendez-vous passé'}
            value={'true'}
            sendValue={() => {}}
            active={isHistory}
            setActive={setIsHistory}
          />
        </div>
      </div>
    );
  };

  const renderWeeklyAppointments = () => {
    if (appointments && appointments.length > 0) {
      return (
        <div className="all-appointments">
          {weeks.map((week, index) => {
            const appointments = groupedAppointments[week];

            return (
              <section
                className="weekly-appointment"
                id={moment(week).format('DD-MM-YYYY')}
                ref={(element) => (scrollNearestAppointmentRef.current[index] = element)}
                key={index}
              >
                <div key={week} className="weekly-appointment-header">
                  <p>{formatedDate(week)}</p>
                </div>
                <div className="weekly-appointments-confirmed">
                  {appointments.map((apt, aptIndex) => {
                    if (apt && apt.id) {
                      const previousApt = appointments[aptIndex - 1];
                      if (
                        !previousApt ||
                        (apt && !moment(apt?.rdv_at ?? null).isSame(previousApt.rdv_at, 'day'))
                      ) {
                        return (
                          <div key={aptIndex} className="appointment-with-date">
                            <AgendaDate date={apt?.rdv_at} />
                            <AgendaRow
                              setAppointmentSelected={getAppointmentSelected}
                              setActionsConfirmationAppointment={handleActionsConfirmationAppointment}

                              type={apt?.appointment_type ?? null}
                              appointment={apt}
                              isBeneficiary={isBeneficiary}
                              user={user}
                              selectable={true}
                              onSelect={handleAppointmentSelect}
                              isSelected={selectedAppointmentId === apt.id}
                            />
                          </div>
                        );
                      }
                      return (
                        <div className="appointment-without-date" key={aptIndex}>
                          <AgendaRow
                            setAppointmentSelected={getAppointmentSelected}
                            setActionsConfirmationAppointment={handleActionsConfirmationAppointment}

                            type={apt?.appointment_type ?? null}
                            appointment={apt}
                            isBeneficiary={isBeneficiary}
                            user={user}
                            selectable={true}
                            onSelect={handleAppointmentSelect}
                            isSelected={selectedAppointmentId === apt.id}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </section>
            );
          })}
        </div>
      );
    } else {
      return <p className="no-appointment">Aucun rendez - vous prévu pour le moment...</p>;
    }
  };

  const renderContent = () => {
    return (
      <div>
        <div className="calendar-page-content">
          <div className="calendar-page-all-appointments">
            {appointmentPending &&
              appointmentPending.length > 0 &&
              renderAppointmentsConfirmation()}
            <div className="calendar-page-all-confirmed-appointments">
              <div className="calendar-page-all-appointments-header">
                {renderFilters()}
                {!isBeneficiary && renderButtonsActions()}
              </div>
              {renderWeeklyAppointments()}
            </div>
          </div>
          <div className="calendar-page-right-side">
            {appointmentSelected ? (
              <AgendaSidebar
                appointmentProps={appointmentSelected}
                onClickBack={onClickBackSidebar}
                setEditAppointment={getEditAppointment}
                setDeleteAppointment={getDeleteAppointment}
                setDeclineAppointment={getDeclineAppointment}
                setActionsConfirmationAppointment={handleActionsConfirmationAppointment}
                setToaster={getToasters}
              />
            ) : (
              <AgendaCalendar
                appointments={appointments}
                viewSpecificAppointment={getDateViewSpecificAppointment}
              />
            )}
          </div>
        </div>
        {showAddAppointmentModal && (
          <AddAppointmentPopin
            open={showAddAppointmentModal}
            onClose={() => setShowAddAppointmentModal(false)}
            setAppointment={getNewAppointment}
            isModule={false}
            isBeneficiaryId={null}
            filter={{ all: filterAll, indiv: filterIndividual, collective: filterCollective }}
          />
        )}
        {showDeclineAppointmentPopin && (
          <DeclineAppointmentPopin
            open={showDeclineAppointmentPopin}
            onClose={() => setShowDeclineAppointmentPopin(false)}
            appointment={appointmentRefused.appointment}
            handleDeclineAppointment={() =>
              handleActionsConfirmationAppointment(appointmentRefused)
            }
          />
        )}
      </div>
    );
  };

  const renderPageAside = () => {
    return isBeneficiary ? <BeneficiaryPageAside /> : <ConsultantPageAside />;
  };

  const aside = renderPageAside();
  const content = <TabbedContentLayout content={renderContent()} />;

  return (
    <div className="page">
      <div className="calendar-page">
        <LeftAsideLayout aside={aside} content={content} />
        <div className="notification-container">
          {toasters.map((toaster) => (
            <Toaster
              key={toaster.id}
              title={toaster.title}
              message={toaster.message}
              type={toaster.type}
              onClose={() => removeToaster(toaster.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(AgendaPage);
