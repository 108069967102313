import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import './DataTable.scss';
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
import CircleButton from '../../atoms/CircleButton/CircleButton';

const DataTable = ({ columns = [], data = null, postResendTest }) => {
  return (
    <table className="tests-list">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(data && data.length > 0) ? (
          data.map((row, index) => (
            <tr key={index}>
              <td className="test-name">{row.test_title}</td>
              <td>{row.test_status}</td>
              <td>{row.assessment_start_date}</td>
              <td>{row.assessment_end_date}</td>
              <td>
                {row.company_report_pdf_link !== null ? (<a
                  style={{ textDecoration: 'underline', color: 'var(--main-color)' }}
                  href={row.company_report_pdf_link}
                  target="_blank"
                >
                  Rapport consultant
                </a>) : ''}
                
              </td>
              <td>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <CircleButton disabled={!(row.candidate_report_pdf_link !== null)} icon={Icon.icon.File} onClick={() => window.open(row.candidate_report_pdf_link)} />
                    <CircleButton icon={Icon.icon.Mail} onClick={() => postResendTest(row.id)} />
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">Auncunes données.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DataTable;
