/* eslint-disable no-unused-vars */
// /* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { connect } from 'react-redux';

import {
  updateAppointmentById,
  deleteAppointmentById,
  getContactsAppointments,
  deleteParticipantAppointment,
} from '../../../api/AppointmentsApi';
import { getConsultantInfos } from '../../../api/ConsultantApi';
import { getAppointmentById } from '../../../api/AppointmentsApi';
import { updateModule } from '../../../api/CourseApi';

import { BackgroundIcon } from '../../../components/atoms/BackgroundIcon/BackgroundIcon';
import BigCombo from '../../../components/molecules/BigCombo/BigCombo';
import CheckBoxField from '../../../components/molecules/CheckBoxField/CheckBoxField';
import { DeleteAppointmentPopin } from '../DeleteAppointmentPopin/DeleteAppointmentPopin';
import { DropdownTag } from '../../../components/molecules/DropdownTag/DropdownTag';
import InputField from '../../../components/molecules/InputField/InputField';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { SorterButton } from '../../../components/atoms/SorterButton/SorterButton';

import moment from 'moment';
import 'moment/locale/fr';
import './EditAppointmentPopin.scss';
import { checkCalendarSync, getUserCronofyProfile } from '../../../api/CronofyApi';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
});

export const EditAppointmentPopin = ({
  open,
  onClose,
  appointment,
  user,
  setEditAppointment,
  setDeletedAppointment,
  onCloseDeleteModal,
  setToaster,
  isModule,
  module,
  talent,
}) => {
  const [actionButton, setActionButton] = useState('Modifier');
  const [appointmentEditCause, setAppointmentEditCause] = useState('update');
  const [appointmentEditCauses, setAppointmentEditCauses] = useState([
    { value: 'update', label: 'Modifier le rendez-vous', isSelected: true },
    { value: 'rescheduled', label: 'Reporter le rendez-vous', isSelected: false },
    { value: 'cancel', label: 'Annuler le rendez-vous', isSelected: false },
  ]);
  const [appointmentTime, setAppointmentTime] = useState({ date: '', rdv_at: '', rdv_to: '' });
  const [isIndividualAppointment, setIsIndividualAppointment] = useState();
  const [isOpen, setIsOpen] = useState(open);
  const [newAppointment, setNewAppointment] = useState({});
  const [calendarId, setCalendarId] = useState('');
  const [conferencingProfile, setConferencingProfile] = useState(null);
  const [showDeleteAppointmentModal, setShowDeleteAppointmentModal] = useState(false);
  const [users, setUsers] = useState();
  const [consultant, setConsultant] = useState({ id: null, first_name: '', last_name: '' });
  const [comment, setComment] = useState(null);
  const [disabledActionButton, setDisabledActionButton] = useState(true);
  const [showVisioLink, setShowVisioLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fuseauHoraire, setFuseauHoraire] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  // use effect
  useEffect(() => {
    getContactsAppointments(null, user.id).then((response) => {
      setUsers(response.users_to);
    });
  }, [user.id]);

  useEffect(() => {
    switch (appointmentEditCause) {
      case 'update':
        setActionButton('Modifier');
        break;
      case 'rescheduled':
        setActionButton('Reporter');
        break;
      case 'cancel':
        setActionButton('Annuler le rendez - vous');
        break;
      default:
        setActionButton('Modifier');
        break;
    }
  }, [appointmentEditCause]);

  useEffect(() => {
    if (appointmentEditCause === 'rescheduled') {
      // Si nouveau commentaire, si la date à changer, si l'heure à changer
      const isNewComment = appointment.comment !== newAppointment.comment;
      const isNewDate = appointment.rdv_at !== newAppointment.rdv_at;
      isNewComment && isNewDate ? setDisabledActionButton(false) : setDisabledActionButton(true);
    } else if (appointmentEditCause === 'update') {
      const isNewAppointment = appointment !== newAppointment;
      isNewAppointment ? setDisabledActionButton(false) : setDisabledActionButton(true);
    } else if (appointmentEditCause === 'cancel') {
      setDisabledActionButton(false);
    }
  }, [appointmentEditCause, newAppointment, comment, appointment]);

  // useEffect(() => {
  //     const { appointment_type } = appointment
  //     setNewAppointment(appointment);
  //     setIsIndividualAppointment(appointment_type === "Individual" ? true : false)
  // }, [appointment]);
  useEffect(() => {
    const appointment_type = appointment?.appointment_type ?? null;
      if(appointment && appointment?.appointment_id) {
        getAppointmentById(appointment.appointment_id).then((response) => {
            setNewAppointment(response.appointment)
            getConsultantInfos(response.appointment.user_from).then((response) => {
                setConsultant({first_name: response.consultant.user.first_name, last_name: response.consultant.user.last_name})
            })
        })
    } else {
      setNewAppointment(appointment);
    }

    // Vérifier si appointment_type est défini et non vide
    if (appointment_type != null && appointment_type !== '') {
      setIsIndividualAppointment(appointment_type === 'Individual' ? true : false);
    } else {
      setIsIndividualAppointment(true); // Si appointment_type est manquant ou vide, définir à true
    }
  }, [appointment]);

  useEffect(() => {
    const old_rdv_at = moment(appointment?.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
    const old_rdv_to = moment(appointment?.rdv_to, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');

    const formated_date = moment(appointmentTime?.date, 'DD/MM/YYYY').format('YYYY-MM-DD');

    const new_rdv_at = moment(formated_date + ' ' + old_rdv_at, 'YYYY-MM-DD HH:mm:ss').format(
      'YYYY-MM-DD HH:mm:ss',
    );
    const new_rdv_to = moment(formated_date + ' ' + old_rdv_to, 'YYYY-MM-DD HH:mm:ss').format(
      'YYYY-MM-DD HH:mm:ss',
    );

    appointmentTime.date &&
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        rdv_at: new_rdv_at,
        rdv_to: new_rdv_to,
      }));
    appointmentTime.rdv_at &&
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        rdv_at: moment(appointmentTime.rdv_at, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
      }));
    appointmentTime.rdv_to &&
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        rdv_to: moment(appointmentTime.rdv_to, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'),
      }));
  }, [appointment, appointmentTime]);

  useEffect(() => {
    getConsultantInfos(appointment?.user_from).then((response) => {
      const consultant = response.consultant.user;
      setConsultant({
        id: consultant.id,
        first_name: consultant.first_name,
        last_name: consultant.last_name,
      });
    });

    checkCalendarSync(user.id).then((response) => {
      setCalendarId(response);
    });

    const data = {
      user_id: user.id,
      calendar_id: calendarId,
    };

    getUserCronofyProfile(data).then((response) => {
      const conferencingProfiles = response['cronofy.data']['conferencing_profiles'];

      if (conferencingProfiles.length > 1) {
        const maxIndex = conferencingProfiles.reduce((maxIndex, currentProfile, currentIndex) => {
          return currentIndex > maxIndex ? currentIndex : maxIndex;
        }, 0);

        const providerName = conferencingProfiles[maxIndex]['provider_name'];
        setConferencingProfile(providerName);
      } else if (conferencingProfiles.length === 1) {
        const providerName = conferencingProfiles['provider_name'];
        setConferencingProfile(providerName);
      }
    });
  }, [appointment, calendarId, user.id]);

  // methods

  const handleParsingUrl = (input) => {
    const { id, value } = input;
    const newInputValue = value;

    const zoomRegex = /zoom.us/;
    const teamsRegex = /teams\.microsoft\.com/;
    const meetRegex = /meet\.google\.com/;
    const gotoRegex = /gotomeet\.me/;
    const indeedRegex = /indeed\.com\/video/;

    if (zoomRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'zoom',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    } else if (teamsRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'ms_teams',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    } else if (meetRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'google_meet',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    } else if (gotoRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'go_to',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    } else if (indeedRegex.test(newInputValue)) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        provider_description: 'indeed',
      }));
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, join_url: value }));
    }
  };

  // function wait(ms) {
  //     return new Promise((resolve) => {
  //         setTimeout(resolve, ms);
  //     });
  // }

  async function handleEditAppointment() {
    //loader
    setLoading(true);
    // await wait(2000).then(() => {
    // })
    if (!isModule) {
      if (appointmentEditCause === 'update') {
        await updateAppointmentById(newAppointment.id, newAppointment)
          .then((response) => {
            setToaster({
              title: 'Modification du rendez-vous',
              message: 'Le rendez-vous a été modifié avec succès.',
              type: 'success',
            });
            setEditAppointment(response.appointment);
            setIsOpen(false);
            window.location.reload();
          })
          .catch((error) => {
            setToaster({
              title: 'Erreur de modification du rendez - vous',
              message: 'Une erreur est survenue lors de la modification du rendez-vous.',
              type: 'error',
            });
          });
      } else if (appointmentEditCause === 'rescheduled') {
        let appointmentTmp = newAppointment;
        var tmp_at = moment.tz(newAppointment.rdv_at, 'YYYY-MM-DD HH:mm:ss', fuseauHoraire.id);
        var tmp_to = moment.tz(newAppointment.rdv_to, 'YYYY-MM-DD HH:mm:ss', fuseauHoraire.id);
        appointmentTmp.rdv_at = tmp_at.clone().tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss');
        appointmentTmp.rdv_to = tmp_to.clone().tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss');
        appointmentTmp.status = 'rescheduled';
        await updateAppointmentById(appointmentTmp.id, appointmentTmp)
          .then(() => {
            setToaster({
              title: 'Report du rendez-vous',
              message:
                'Votre rendez-vous a bien été reporté. Les participants recevront un e-mail.',
              type: 'success',
            });
          })
          .catch((error) => {
            setToaster({
              title: 'Erreur de report',
              message: 'Une erreur est survenue lors du report du rendez-vous.',
              type: 'error',
            });
          });
        setEditAppointment(appointmentTmp);
        // window.location.reload();

        setIsOpen(false);
      } else if (appointmentEditCause === 'cancel') {
        setIsOpen(false);
        setShowDeleteAppointmentModal(true);
      }
    } else {
      if (appointmentEditCause === 'update') {
        module.title = newAppointment.title;
        module.data[0] = newAppointment;
        await updateModule(module)
          .then((response) => {
            setToaster({
              title: 'Modification du rendez-vous',
              message: 'Le rendez-vous a été modifié avec succès.',
              type: 'success',
            });
            setEditAppointment(response.module);
          })
          .catch((error) => {
            setToaster({
              title: 'Erreur de modification du rendez - vous',
              message: 'Une erreur est survenue lors de la modification du rendez-vous.',
              type: 'error',
            });
          });
        updateAppointmentById(newAppointment.id, newAppointment);
        window.location.reload();
      } else if (appointmentEditCause === 'rescheduled') {
        newAppointment.status = 'rescheduled';
        module.data[0] = newAppointment;
        await updateModule(module)
          .then((response) => {
            setEditAppointment(response.module);
            setToaster({
              title: 'Report du rendez-vous',
              message: 'Votre rendez-vous a bien été reporté. Votre talent recevra un e-mail.',
              type: 'success',
            });
          })
          .catch((error) => {
            setToaster({
              title: 'Erreur de report',
              message: 'Une erreur est survenue lors du report du rendez-vous.',
              type: 'error',
            });
          });
        updateAppointmentById(newAppointment.id, newAppointment);
        // window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 100);

      } else if (appointmentEditCause === 'cancel') {
        setIsOpen(false);
        setShowDeleteAppointmentModal(true);
      }
    }
    setLoading(false);
  }

  const handleOnChangeInputTime = (input) => {
    const { id, value } = input;
    let rdv_at = '';
    let rdv_to = '';
    let today = moment().format('DD/MM/YYYY');

    if (id === 'date') {
      setAppointmentTime((prevAppointmentTime) => ({
        ...prevAppointmentTime,
        date: moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      }));
    }

    if (id === 'rdv_at' && appointmentTime.date) {
      rdv_at = `${appointmentTime.date} ${value}`;
      setAppointmentTime((prevAppointmentTime) => ({ ...prevAppointmentTime, rdv_at: rdv_at }));
    } else if (id === 'rdv_at' && !appointmentTime.date) {
      rdv_at = `${today} ${value}`;
      setAppointmentTime((prevAppointmentTime) => ({ ...prevAppointmentTime, rdv_at: rdv_at }));
    }

    if (id === 'rdv_to' && appointmentTime.date) {
      rdv_to = `${appointmentTime.date} ${value}`;
      setAppointmentTime((prevAppointmentTime) => ({ ...prevAppointmentTime, rdv_to: rdv_to }));
    } else if (id === 'rdv_to' && !appointmentTime.date) {
      rdv_to = `${today} ${value}`;
      setAppointmentTime((prevAppointmentTime) => ({ ...prevAppointmentTime, rdv_to: rdv_to }));
    }
  };

  const handleCommentOnChange = (event) => {
    newAppointment.comment = event.target.value;
    setComment(event.target.value);
  };

  const getDeleteAppointment = () => {
    setShowDeleteAppointmentModal(false);
  };

  const getValueSorter = (filter) => {
    const { value } = filter;
    setAppointmentEditCause(value);
  };

  const handleGetDropdownChoices = (choices) => {
    const { id, values } = choices;
    id === 'user_from' &&
      values &&
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, [id]: values[0]?.id }));
    isIndividualAppointment &&
      id === 'users_to' &&
      values &&
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        [id]: { individuals: values, groups: [] },
      }));
    if (!isIndividualAppointment && id === 'users_to' && values) {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        [id]: { individuals: values, groups: [] },
      }));
    }
    if (id === 'fuseau_form' && values) {
        setFuseauHoraire(values[0]);
      }
  };

  const handleOnChangeInput = (input) => {
    const { id, value } = input;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, [id]: value }));
  };

  const handleOnChangeSelect = (select) => {
    const { id, value } = select;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, [id]: value.value }));
    select.value.value === 'none'
      ? setNewAppointment((prevAppointment) => ({ ...prevAppointment, is_videocall: 0 }))
      : setNewAppointment((prevAppointment) => ({ ...prevAppointment, is_videocall: 1 }));
    select.value.value === 'explicit' ? setShowVisioLink(true) : setShowVisioLink(false);

    if (select.value.value !== 'explicit') {
      setNewAppointment((prevAppointment) => ({ ...prevAppointment, videocall_url: null }));
    } else {
      setNewAppointment((prevAppointment) => ({
        ...prevAppointment,
        videocall_url: appointment.videocall_url,
      }));
    }
  };

  const handleOnChangeDescription = (description) => {
    const { target } = description;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, public_notes: target.value }));
  };

  const handleOnChangePrivateNote = (note) => {
    const { target } = note;
    setNewAppointment((prevAppointment) => ({ ...prevAppointment, private_notes: target.value }));
  };

  const handleOnToggleCheckbox = (checkbox) => {
    setNewAppointment((prevAppointment) => ({
      ...prevAppointment,
      is_tallysheet: checkbox.value ? 1 : 0,
    }));
  };

  const handleDeleteAppointment = () => {
    if (!isModule) {
      deleteAppointmentById(appointment.id)
        .then(() => {
          setShowDeleteAppointmentModal(false);
          onCloseDeleteModal(false);
          setDeletedAppointment(appointment);
          setToaster({
            title: 'Suppression du rendez - vous',
            message: 'Le rendez - vous a été supprimé avec succès.',
            type: 'success',
          });
        })
        .catch((error) => {
          setToaster({
            title: 'Erreur de suppression',
            message: 'Une erreur est survenue lors de la suppression du rendez - vous.',
            type: 'error',
          });
        });
    } else {
      deleteAppointmentById(appointment.id);
      module.data[0] = appointment;
      setDeletedAppointment(module);
      setShowDeleteAppointmentModal(false);
      onCloseDeleteModal(false);
    }
  };

  const handleOnDeleteParticipant = (participant) => {
    deleteParticipantAppointment(appointment.id, participant.user_id);
  };

  useEffect(() => {
    if (appointment.videocall_type === 'explicit') {
      setShowVisioLink(true);
    }
  }, [appointment]);

  // render components
  const renderTitleSection = (sectionTitle, icon) => {
    return (
      <div className="appointment-popin-section-title">
        <BackgroundIcon icon={icon} />
        <p>{sectionTitle}</p>
      </div>
    );
  };

  const renderCauseOfUpdate = () => {
    return (
      <div className="edit-appointment-popin-cause">
        {appointmentEditCauses.map((cause, index) => (
          <SorterButton
            key={index}
            label={cause.label}
            value={cause.value}
            sendValue={getValueSorter}
            active={cause.isSelected}
            setActive={() => {
              const updatedAppointmentsCauses = appointmentEditCauses.map((a, idx) => ({
                ...a,
                isSelected: idx === index,
              }));
              setAppointmentEditCauses(updatedAppointmentsCauses);
            }}
          />
        ))}
      </div>
    );
  };

  const renderTitleAppointment = () => {
    return (
      <div className="appointment-popin-title">
        <InputField
          title={'Titre du rendez - vous'}
          name={'title'}
          value={newAppointment.title}
          type={'text'}
          required
          onChange={handleOnChangeInput}
          disabled={appointmentEditCause === 'update' ? false : true}
        />
      </div>
    );
  };

  const renderTimeAppointment = () => {
    const fuseauxHoraires = moment.tz.names();
    let formattedFuseauxHoraires = {};
    formattedFuseauxHoraires.groups = [];
    formattedFuseauxHoraires.individuals = fuseauxHoraires.map((fuseau) => {
      return {
        id: fuseau,
        first_name: fuseau,
        last_name: '',
      };
    });
    return (
      <div className="appointment-popin-time">
        {renderTitleSection('Date et horaires', 'calendar')}
        <div>
          <DropdownTag
            id={'fuseau_form'}
            label={'Fuseau horaire'}
            defaultValue={[
              {
                id: Intl.DateTimeFormat().resolvedOptions().timeZone,
                first_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
                last_name: '',
              },
            ]}
            placeholder={'Sélectionner un fuseau...'}
            dropdownArray={formattedFuseauxHoraires}
            multipleChoice={false}
            sendSelectChoices={handleGetDropdownChoices}
          />
        </div>
        <div className="appointment-popin-inputs-time">
          <InputField
            title={'Date actuelle'}
            placeholder={'Ajouter un date...'}
            name={'date'}
            value={
              appointment.rdv_at
                ? moment(appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
                : ''
            }
            type={'date'}
            required
            disabled
            onChange={handleOnChangeInputTime}
          />
          <InputField
            title={'De'}
            placeholder={''}
            name={'rdv_at'}
            value={
              newAppointment.rdv_at
                ? moment(appointment.rdv_at, 'DD-MM-YYYY HH:mm:ss').format('HH:mm')
                : moment(appointment.rdv_at, 'DD-MM-YYYY HH:mm').format('HH:mm')
            }
            type={'time'}
            required
            disabled
            onChange={handleOnChangeInputTime}
          />
          <InputField
            title={'A'}
            placeholder={''}
            name={'rdv_to'}
            value={
              newAppointment.rdv_to
                ? moment(appointment.rdv_to, 'DD-MM-YYYY HH:mm:ss').format('HH:mm')
                : moment(appointment.rdv_to, 'DD-MM-YYYY HH:mm').format('HH:mm')
            }
            type={'time'}
            required
            disabled
            onChange={handleOnChangeInputTime}
          />
        </div>
        {appointmentEditCause === 'rescheduled' && (
          <div className="appointment-popin-inputs-time">
            <p>{appointment ? appointment.date : 'null'}</p>

            <InputField
              title={'Saissisez une nouvelle date'}
              placeholder={'Ajouter un date...'}
              name={'date'}
              value={
                newAppointment && newAppointment.rdv_at
                  ? moment(newAppointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
                  : ''
              }
              type={'date'}
              required
              onChange={handleOnChangeInputTime}
            />
            <InputField
              title={'De'}
              placeholder={''}
              name={'rdv_at'}
              value={
                newAppointment.rdv_at
                  ? moment(newAppointment.rdv_at, 'DD-MM-YYYY HH:mm').format('HH:mm')
                  : ''
              }
              type={'time'}
              required
              onChange={handleOnChangeInputTime}
            />
            <InputField
              title={'A'}
              placeholder={''}
              name={'rdv_to'}
              value={
                newAppointment.rdv_to
                  ? moment(newAppointment.rdv_to, 'DD-MM-YYYY HH:mm').format('HH:mm')
                  : ''
              }
              type={'time'}
              required
              onChange={handleOnChangeInputTime}
            />
          </div>
        )}
      </div>
    );
  };

  const renderReasonRescheduled = () => {
    return (
      <div className="appointment-popin-reason-rescheduled">
        {renderTitleSection('Motif de report de rendez-vous', 'calendar')}
        <textarea onChange={handleCommentOnChange} />
      </div>
    );
  };

  const compareNames = (a, b) => {
    const nameA = a.first_name || a.name || '';
    const nameB = b.first_name || b.name || '';
  
    if (!nameA || !nameB) {
      return a.id - b.id;
    }
    const upperCaseNameA = nameA.toUpperCase();
    const upperCaseNameB = nameB.toUpperCase();
  
    if (upperCaseNameA < upperCaseNameB) {
      return -1;
    }
    if (upperCaseNameA > upperCaseNameB) {
      return 1;
    }
    return 0;
  };

  const renderConsultantAppointment = () => {
    const consultantsIndividuals = users?.individuals?.filter((user) => user.role === 'consultant');
    const consultantGroups = users?.groups?.filter((user) => user.role === 'consultant');
    const consultants = { individuals: consultantsIndividuals?.sort(compareNames), groups: consultantGroups?.sort(compareNames) };
    return (
      <div className="appointment-popin-consultant">
        {renderTitleSection('Consultant(s)', 'person')}
        <DropdownTag
          id={'user_from'}
          label={'Consultant(s)'}
          defaultValue={[
            {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
              user_id: user.id,
            },
          ]}
          placeholder={'Sélectionner un consultant...'}
          dropdownArray={consultants}
          multipleChoice={true}
          sendSelectChoices={handleGetDropdownChoices}
          disabled={appointmentEditCause === 'update' ? false : true}
          user={user}
        />
      </div>
    );
  };

  const renderNumberOfParticpants = () => {
    return (
      <div className="appointment-popin-number-of-participants">
        {renderTitleSection('Nombre de participants', 'people')}
        <InputField
          name={'nb_seats'}
          value={newAppointment ? newAppointment.nb_seats : ''}
          type={'number'}
          onChange={handleOnChangeInput}
          disabled={appointmentEditCause === 'update' ? false : true}
        />
      </div>
    );
  };

  const renderTalentsAppointment = () => {
    const title = isIndividualAppointment ? 'Talent' : 'Talents';
    const placeholder = isIndividualAppointment
      ? 'Sélectionner un talent...'
      : 'Sélectionner des talents, des groupes...';
    const individuals = appointment.users_to?.individuals || [];
    const groups =
      appointment.users_to?.groups?.reduce(
        (acc, group) => acc.concat(group?.group_users || []),
        [],
      ) || [];
    const appointmenttalents = [...individuals, ...groups];

    const talentIndividuals = users?.individuals?.filter((user) => user.role === 'beneficiary');

    const talentGroups =
      users?.groups?.reduce((acc, group) => {
        const groupBeneficiaryUsers = group.group_users.filter(
          (user) => user.role === 'beneficiary',
        );
        if (groupBeneficiaryUsers.length > 0) {
          acc.push({ ...group, group_users: groupBeneficiaryUsers });
        }

        return acc;
      }, []) || [];

      const talents = { individuals: talentIndividuals?.sort(compareNames), groups: talentGroups?.sort(compareNames) };

    return (
      <div className="appointment-popin-talents">
        {renderTitleSection(title, 'people')}
        {isModule ? (
          <DropdownTag
            id={'users_to'}
            label={title}
            disabled={true}
            defaultValue={[
              { user_id: talent.id, first_name: talent.first_name, last_name: talent.last_name },
            ]}
            placeholder={placeholder}
            dropdownArray={talents}
            multipleChoice={isIndividualAppointment ? false : true}
            sendSelectChoices={handleGetDropdownChoices}
            isActionOnDeleteTag={true}
            handleOnDeleteTag={handleOnDeleteParticipant}
            user={user}
          />
        ) : (
          <DropdownTag
            id={'users_to'}
            defaultValue={appointmenttalents}
            label={title}
            placeholder={placeholder}
            dropdownArray={talents}
            multipleChoice={isIndividualAppointment ? false : true}
            sendSelectChoices={handleGetDropdownChoices}
            disabled={appointmentEditCause === 'update' ? false : true}
            isActionOnDeleteTag={true}
            handleOnDeleteTag={handleOnDeleteParticipant}
            user={user}
          />
        )}
      </div>
    );
  };

  const renderAppointmentProcedure = () => {
    //const options = [{ value: "zoom", label: "Zoom" }, { value: "google", label: "Google" }, { value: "mytalents", label: "MyTalents" }, { value: "teams", label: "Teams" }, { value: "none", label: "Aucun" }]
    let options = '';
    let warning = '';

    let type = '';
    if (conferencingProfile === null || conferencingProfile === undefined) {
      options = [
        { value: 'twilio', label: 'Visio MyTalents', type: 'Twilio' },
        { value: 'explicit', label: 'Lien de visio personnalisé', type: 'Perso' },
        { value: 'tel', label: 'Téléphone', type: 'Tel' },
        { value: 'none', label: 'Aucun', type: null },
      ];
      if (appointment.videocall_type) {
        const option = options.filter((element) => element.value === appointment.videocall_type)[0];
        if (option) {
          type = option.label;
        }
      }
      warning = '* Vous pouvez ajouter un outil de visio favori depuis l\'onglet "Profil"';
    } else {
      options = [
        { value: 'default', label: 'Outil de visio favori', type: conferencingProfile },
        { value: 'explicit', label: 'Lien de visio personnalisé', type: 'Perso' },
        { value: 'twilio', label: 'Visio MyTalents', type: 'Twilio' },
        { value: 'tel', label: 'Téléphone', type: 'Tel' },
        { value: 'none', label: 'Aucun', type: null },
      ];
      if (appointment.videocall_type) {
        const option = options.filter((element) => element.value === appointment.videocall_type)[0];
        if (option) {
          type = option.label;
        }
      }
    }

    if (!isIndividualAppointment) {
      options = options.filter((option) => option.value !== 'twilio');
    }

    // const selectedOption = options.find(option => option.type === newAppointment.videocall_type);

    const selectedOption = options;

    return (
      <div className="appointment-popin-procedures">
        <div className="appointment-popin-procedures-1">
          {renderTitleSection('Lien de visio', 'visio')}
          <BigCombo
            id={'videocall_type'}
            title={'Outils visio'}
            value={appointment.videocall_type ? type : 'none'}
            options={selectedOption}
            onChange={handleOnChangeSelect}
          />
          <div>
            <p className="appointment-visio-warning">{warning}</p>
          </div>
          <InputField
            title={'Lien'}
            placeholder={'Insérer le lien...'}
            name={showVisioLink ? 'videocall_url' : 'videocall_url_hidden'}
            value={newAppointment ? newAppointment.videocall_url : ''}
            type={'text'}
            onChange={(e) => {
              handleOnChangeInput(e);
              handleParsingUrl(e);
            }}
          />
        </div>
        <div className="appointment-popin-procedures-2">
          <div className="appointment-popin-procedures-address">
            {renderTitleSection('Adresse', 'pin')}
            <InputField
              title={''}
              placeholder={'Adresse...'}
              name={'address'}
              value={newAppointment.address ? newAppointment.address : ''}
              type={'text'}
              onChange={handleOnChangeInput}
            />
          </div>
          <div className="appointment-popin-procedures-phone">
            {renderTitleSection('Téléphone', 'phone')}
            <InputField
              title={''}
              placeholder={'Téléphone...'}
              name={'phone'}
              value={newAppointment.phone ? newAppointment.phone : ''}
              type={'text'}
              onChange={handleOnChangeInput}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderAppointmentSignature = () => {
    const { is_tallysheet } = newAppointment;
    const isTallysheet = is_tallysheet === 1;
    return (
      <div className="appointment-popin-signature d-none">
        {renderTitleSection('Emargement', 'document-read')}
        <CheckBoxField
          id={'signature'}
          name={'signature'}
          label={"Mettre en place une feuille d'émargement"}
          // value={appointmentSignature}
          value={isTallysheet ? true : false}
          onChange={handleOnToggleCheckbox}
          radio={true}
          disabled={appointmentEditCause === 'update' ? false : true}
        />
      </div>
    );
  };

  const renderAppointmentDescription = () => {
    return (
      <div className="appointment-popin-description">
        {renderTitleSection('Description', 'text')}
        <textarea
          onChange={handleOnChangeDescription}
          value={newAppointment?.public_notes ?? ''}
          disabled={appointmentEditCause === 'update' ? false : true}
        >{newAppointment?.public_notes ?? ''}</textarea>
      </div>
    );
  };

  const renderAppointmentPrivateNote = () => {
    return (
      <div className="appointment-popin-description">
        {renderTitleSection('Note privée', 'text')}
        <textarea
          onChange={handleOnChangePrivateNote}
          value={newAppointment?.private_notes ?? ''}
          disabled={appointmentEditCause === 'update' ? false : true}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      appointment && (
        <div className="edit-appointment-popin-content">
          {renderCauseOfUpdate()}
          {renderTitleAppointment()}
          {renderTimeAppointment()}
          {appointmentEditCause === 'rescheduled' && renderReasonRescheduled()}
          {consultant && renderConsultantAppointment()}
          {!isIndividualAppointment && renderNumberOfParticpants()}
          {renderTalentsAppointment()}
          {renderAppointmentProcedure()}
          {renderAppointmentSignature()}
          {renderAppointmentDescription()}
          {renderAppointmentPrivateNote()}
        </div>
      )
    );
  };

  return (
    <div>
      <Modal
        onClosePopin={onClose}
        openPopin={isOpen}
        title={'Modification du rendez - vous'}
        content={renderContent()}
        cancelButton={'Retour'}
        disabledActionButton={disabledActionButton}
        actionButton={actionButton}
        handleActionButton={handleEditAppointment}
        loading={loading}
      />

      {showDeleteAppointmentModal && (
        <DeleteAppointmentPopin
          open={showDeleteAppointmentModal}
          onClose={() => {
            setShowDeleteAppointmentModal(false);
            setIsOpen(false);
          }}
          appointment={appointment}
          setDeleteAppointment={getDeleteAppointment}
          handleDeleteAppointment={handleDeleteAppointment}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, null)(EditAppointmentPopin);
